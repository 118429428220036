// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-como-funciona-js": () => import("./../../../src/pages/como-funciona.js" /* webpackChunkName: "component---src-pages-como-funciona-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-politica-privacidade-js": () => import("./../../../src/pages/politica-privacidade.js" /* webpackChunkName: "component---src-pages-politica-privacidade-js" */),
  "component---src-pages-quero-usar-js": () => import("./../../../src/pages/quero-usar.js" /* webpackChunkName: "component---src-pages-quero-usar-js" */),
  "component---src-pages-termos-de-uso-embedado-js": () => import("./../../../src/pages/termos-de-uso-embedado.js" /* webpackChunkName: "component---src-pages-termos-de-uso-embedado-js" */),
  "component---src-pages-termos-de-uso-js": () => import("./../../../src/pages/termos-de-uso.js" /* webpackChunkName: "component---src-pages-termos-de-uso-js" */)
}

